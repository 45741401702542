@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


/* Removing Browsers Default */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/* Removing Browsers Default End */

/* Root Colors */

:root {
    --logo_clr: #F8C100;
    --text_clr: #0B0B45;
}

/* Rot Colors End */


/* Common Title Css */
.title {
    background: linear-gradient(to bottom, hsl(47, 100%, 49%), rgba(0, 0, 0, 0.326));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.title::after {
    content: '';
    width: 50%;
    margin: auto;
    top: 5px;
    position: relative;
    height: 3px;
    display: block;
    background: linear-gradient(to right, hsl(47, 100%, 49%), rgba(0, 0, 0, 0.326));
}

/* Common Title Css End */

/* Loader Css */

.loader-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Loading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Loader Css End */