.SideNav {
    background-color: rgba(0, 0, 0, 0.445);
    width: 100%;
    padding: 10px 0;
}

.SideNav ul li {
    list-style-type: none;
    color: white;
}

.SideNav ul li:hover {
    color: #F8C100;
}

.SideNav ul {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}