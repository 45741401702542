.NavBar {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.Navlogo {
    display: flex;
    align-items: center;
}

.Navlogo img {
    max-width: 100px;
    max-height: 70px;
}

.navList {
    display: flex;
    align-items: center;
}

.navList ul {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center !important;
    gap: 3rem;
    padding: 10px;
    margin: auto;
}

.navList ul li {
    list-style-type: none;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #0B0B45;
}

.navList ul li:hover {
    cursor: pointer;
    color: #F8C100;
}


/* Media Query  */

@media (max-width:768px) {
    .NavBar {
        flex-direction: row-reverse;
        padding: 0px;
    }

    .navList {
        display: none;
    }

    .Navlogo img {
        max-width: 50px;
        max-height: 50px;
    }
}