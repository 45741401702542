.Icon_page {
    bottom: 0%;
    position: fixed;
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.call,
.whatsApp {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    transition: all 0.5s ease-in-out;
    place-items: center;
    justify-content: center;
    z-index: 999;
    margin-bottom: 5rem;
}

.call{
    margin-left: 2rem;
}

.whatsApp {
    margin-right: 2rem;
}

.call img {
    width: 40%;
    margin: auto;
    display: flex;
    z-index: 999;
}

.whatsApp img {
    width: 50%;
    margin: auto;
    display: flex;
}



/* Animation Css */

.whatsApp::before,
.whatsApp::after,
.call::before,
.call::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    clip-path: circle();
    background-color: rgb(118, 255, 109);
    z-index: -1;
    opacity: 0.7;
    transition: all 0.5s ease-in-out;
}

.whatsApp::before,
.call::before {
    animation: pulse 2s ease-out infinite;
}

.whatsApp::after,
.call::after {
    animation: pulse 2s 1s ease-out infinite;
}


@keyframes pulse {

    0%,
    to {
        transform: scale(0);
    }

    100% {
        transform: scale(0.8);
    }
}


/* Animation Css End */