.footer_container {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0rem;
}

.footer_container_top {
    width: 80%;
    margin: auto;
}

.footer_container_top .iframe_map {
    width: 100%;
    height: 300px;
    padding: 10px;
}

.Footer_Title {
    padding: 10px;
}


.footer_container_bottom {
    display: flex;
    width: 100%;
    /* align-items: center; */
    padding: 10px;
    gap: 3rem;  
}

.footer_container_bottom img {
    width: 70px;
    height: 70px;
    float:right;
}

.Footer_Grid {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ul_li_none li {
    list-style-type: none;
}

.ul_li_none2 li {
    list-style-type: none;
    width: fit-content;
}

.ul_li_none2 li:hover {
    color: #F8C100;
    cursor: pointer;
}

.align_cntr {
    display: flex;
    align-items: center;
}

.align_cntr a {
    text-decoration: none;
}

.square_box {
    width: 20px !important;
    height: 20px !important;
    align-items: center;
}

.foot {
    display: flex;
    align-items: center;
}

@media (max-width:1100px) {

    .footer_container {

        padding: 5px;
    }

    .foot {
        flex-direction: column;
    }

    .footer_container_top {
        width: 100%;
    }
}